import React from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

export default function LeaveCardInitialMessageBox({
  initialMessage,
  triangle,
}) {
  return (
    <Box padding="10px 24px 0px 24px">
      {triangle && (
        <Box paddingLeft="38px">
          <Box
            style={{
              width: "30px",
              height: "16px",
              backgroundColor: "#919699",
              clipPath: `polygon(50% 0, 0 100%, 100% 100%)`,
              marginBottom: "-6px",
            }}
          />
        </Box>
      )}
      <Box
        style={{
          backgroundColor: "#919699",
          color: "white",
          borderRadius: "4px",
          padding: "14px"
        }}
      >
        <Typography>
          {initialMessage}
        </Typography>
      </Box>
    </Box>
  );
}
