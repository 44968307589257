import React from "react";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import OcRequestsMessagesCard from "oc/components/oc-requests-messages-card/oc-requests-messages-card";

export default function LeaveCardMessageBox({
  leaveRequest,
  loading,
  initialMessage,
  disabled,
}) {
  let messages = leaveRequest?.messages
    ? _.cloneDeep(leaveRequest.messages)
    : [];

  if (
    messages.length > 0 &&
    messages[0].createdBy === leaveRequest?.createdBy
  ) {
    initialMessage = messages[0].message;
    messages.shift();
  }

  return (
    <Box>
      <OcRequestsMessagesCard
        messages={messages}
        module="MyLeave"
        loading={loading}
        leaveRequest={leaveRequest}
        disabled={disabled}
      />
    </Box>
  );
}
