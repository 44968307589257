import React, { useState } from "react";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { useQuery, useSubscription, useMutation } from "@apollo/react-hooks";
import { GET_FILTERED_LEAVE_TYPES } from "graphql/queries";
import { LEAVES_SUBSCRIPTION } from "graphql/subcriptions";
import {
  ADD_LEAVE_TYPE_FILTER,
  DELETE_LEAVE_TYPE_FILTER,
} from "graphql/mutations";
import usePolling from "hooks/use-polling";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";

export default function MyLeaveAdminSettingsTypeFilterCard() {
  const { t } = useTranslation();
  const { setOpenMessage } = useOcSnackbar();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState({});
  const [originalLeaveTypes, setOriginalLeaveTypes] = useState({});

  const [
    addLeaveTypeFilter,
    { loading: addMutationLoading, error: addMutationError },
  ] = useMutation(ADD_LEAVE_TYPE_FILTER, {
    onCompleted: (data) => {
      setOpenMessage({
        type: "success",
        message: t("SUCCESSFULLY_SAVED"),
      });
    },
    onError: (error) => {
      setOpenMessage({
        type: "error",
        message: t("UNSUCCESSFULLY_SAVED"),
      });
      console.log({ error });
    },
  });

  const [
    deleteLeaveTypeFilter,
    { loading: deleteMutationLoading, error: deleteMutationError },
  ] = useMutation(DELETE_LEAVE_TYPE_FILTER, {
    onCompleted: (data) => {
      //console.log("completed");
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const {
    data: queryData,
    loading,
    error,
    refetch,
  } = useQuery(GET_FILTERED_LEAVE_TYPES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
    onCompleted: (data) => {
      const initialData = {};
      data.filteredLeaveTypes.forEach((item) => {
        if (item.isSelectable) {
          initialData[item.code] = item.show;
        }
      });
      setSelectedLeaveTypes(initialData);
      setOriginalLeaveTypes(initialData);
    },
  });

  useSubscription(LEAVES_SUBSCRIPTION, {
    fetchPolicy: "network-only",
    onData: () => {
      refetch();
    },
  });

  const data = queryData?.filteredLeaveTypes || [];

  function handleChange(e) {
    const code = e.target.name;
    const show = e.target.checked;
    setSelectedLeaveTypes((prev) => ({
      ...prev,
      [code]: show,
    }));
  }

  function handleSave() {
    Object.keys(selectedLeaveTypes).forEach((code) => {
      const show = selectedLeaveTypes[code];
      if (show) {
        deleteLeaveTypeFilter({ variables: { code } });
      } else {
        addLeaveTypeFilter({ variables: { code } });
      }
    });
    setOriginalLeaveTypes(selectedLeaveTypes);
    setShowConfirmDialog(false);
  }

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  function handleCancel() {
    setShowConfirmDialog(false);
    setSelectedLeaveTypes(originalLeaveTypes);
  }

  //I chose this solution because of array comparison
  function hasChanges() {
    return JSON.stringify(selectedLeaveTypes) !== JSON.stringify(originalLeaveTypes);
  }

  return (
    <Box>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />

      <OcCard
        label={t("GRANT_LEAVE_TYPES")}
        loading={loading || addMutationLoading || deleteMutationLoading}
        error={error || addMutationError || deleteMutationError}
      >
        <Box>
          {data?.map((item, index) => {
            if (!item?.isSelectable) return null;
            return (
              <Box key={`filter-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedLeaveTypes[item.code] || false}
                      onChange={handleChange}
                      name={item.code}
                      color="secondary"
                      disabled={
                        loading || addMutationLoading || deleteMutationLoading
                      }
                    />
                  }
                  label={t(`Leave.${item.code}`)}
                />
              </Box>
            );
          })}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="15px"
          >
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                disableElevation
                onClick={() => refetch()}
              >
                {t("REFRESH")}
              </Button>
            </Box>
            <Box display="flex" flexDirection="row">
              <Button
                variant="outlined"
                color="default"
                disableElevation
                onClick={handleCancel}
              >
                {t("CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setShowConfirmDialog(true)}
                disabled={!hasChanges()}
                style={{ marginLeft: '16px' }}
              >
                {t("SAVE")}
              </Button>
            </Box>
          </Box>
        </Box>
      </OcCard>
    </Box>
  );
}
