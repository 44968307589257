import React from "react";
import accountIcon from "@iconify/icons-mdi/account";
import accountOutIcon from "@iconify/icons-mdi/account-outline";
import bagIcon from "@iconify/icons-mdi/bag-personal";
import bagIconOutline from "@iconify/icons-mdi/bag-personal-outline";
import settingsIcon from "@iconify/icons-mdi/cog";
import settingsOutlineIcon from "@iconify/icons-mdi/cog-outline";
import accountOutlineIcon from "@iconify/icons-mdi/account-cog-outline";
import cafeteriaIcon from "@iconify/icons-mdi/coffee";
import cafeteriaSettingsIcon from "@iconify/icons-mdi/coffee-outline";
import clockIcon from "@iconify/icons-mdi/clock";
import MenuBadges from "my-leave/components/my-leave-menu-badges";
import MenuAdminBadges from "my-leave/components/my-leave-menu-admin-badges";
import MyHrMenuBadges from "my-hr/components/my-hr-menu-badges";
import Arrow from "@iconify/icons-mdi/arrow-right-bottom";
import MyTimeMenuBadges from "my-time/components/my-time-menu-badges";

export const mainMenuChangeRequestsDisabled = {
  id: "myHr",
  type: "item",
  path: "/",
  label: "MyHR",
  icon: accountIcon,
  narrowMenuIcon: accountIcon,
  roles: ["MyHR.user"],
  //notAllowedRoles: ["MyHR.admin"],
  rolesOnly: true,
  licence: "myHR",
};

export const mainMenuChangeRequestsEnabled = {
  id: "myHr",
  type: "collapse",
  path: "/",
  label: "MyHR",
  icon: accountIcon,
  badge: <MyHrMenuBadges urgent={true} />,
  roles: ["MyHR.user"],
  licence: "myHR",
  children: [
    {
      id: "myHr",
      type: "item",
      path: "/",
      label: "MyInfo",
      roles: ["MyHR.user"],
      narrowMenuIcon: accountIcon,
      icon: Arrow,
    },
    {
      id: "myHrMyEditedData",
      type: "item",
      path: "/my-hr/my-requests",
      label: "My data edit request",
      translation: "MY_LEAVE_REQUESTS",
      icon: Arrow,
      narrowMenuIcon: accountIcon,
      roles: ["MyHR.user"],
    },
    {
      id: "DataEditRequestsView",
      type: "item",
      path: "/my-hr/view-requests",
      label: "Data edit requests",
      badge: <MyHrMenuBadges />,
      icon: Arrow,
      translation: "LEAVE_REQUESTS_VIEW",
      narrowMenuIcon: accountIcon,
      roles: ["MyHR.examiner"],
    },
  ],
};

export const mainMenuMyTimeRequestsDisabled = {
  id: "myTime",
  type: "item",
  path: "/",
  label: "MyTime",
  icon: clockIcon,
  roles: ["MyTime.user"],
  rolesOnly: true,
};

export const mainMenu = [
  {
    id: "menuGroup",
    type: "group",
    label: "Menu",
    translation: "MENU",
    roles: ["MyHR.user"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "myLeaveRequestItem",
    type: "item",
    path: "/my-leave/my-requests",
    label: "MyLeave",
    icon: bagIcon,
    narrowMenuIcon: bagIcon,
    roles: ["MyLeave.user"],
    notAllowedRoles: ["MyLeave.examiner", "MyLeave.viewer", "MyLeave.admin"],
    rolesOnly: true,
    licence: "myLeave",
  },
  {
    id: "myLeaveCollapse",
    type: "collapse",
    label: "MyLeave",
    icon: bagIcon,
    narrowMenuIcon: bagIcon,
    badge: <MenuBadges urgent={true} />,
    roles: ["MyLeave.examiner", "MyLeave.viewer", "MyLeave.admin"],
    rolesOnly: true,
    licence: "myLeave",
    children: [
      {
        id: "myLeaves",
        type: "item",
        path: "/my-leave/dashboard",
        label: "Examiner control panel",
        translation: "EXAMINER_CONTROL_PANEL",
        narrowMenuIcon: bagIcon,
        icon: Arrow,
        roles: ["MyLeave.examiner", "MyLeave.viewer"],
      },
      {
        id: "myLeaves",
        type: "item",
        path: "/my-leave/my-requests",
        label: "My leave request",
        translation: "MY_LEAVE_REQUESTS",
        narrowMenuIcon: bagIcon,
        icon: Arrow,
        roles: ["MyLeave.user"],
      },
      {
        id: "leaveRequestsApprove",
        type: "item",
        path: "/my-leave/approve-requests",
        label: "Leave requests",
        translation: "LEAVE_REQUESTS_APPROVE",
        badge: <MenuBadges />,
        icon: Arrow,
        narrowMenuIcon: bagIcon,
        roles: ["MyLeave.examiner"],
      },
      {
        id: "leaveRequestsView",
        type: "item",
        path: "/my-leave/view-requests",
        label: "Leave requests",
        translation: "LEAVE_REQUESTS_VIEW",
        narrowMenuIcon: bagIcon,
        icon: Arrow,
        roles: ["MyLeave.viewer"],
      },
      {
        id: "leaveRequestsHandle",
        type: "item",
        path: "/my-leave/handle-requests",
        label: "Leave requests",
        translation: "LEAVE_REQUESTS_HANDLE",
        badge: <MenuAdminBadges />,
        icon: Arrow,
        narrowMenuIcon: bagIcon,
        roles: ["MyLeave.admin"],
      },
    ],
  },
  {
    id: "myTime",
    type: "item",
    path: "/my-time/employee-schedule",
    label: "MyTime",
    icon: clockIcon,
    narrowMenuIcon: clockIcon,
    roles: ["MyTime.user"],
    notAllowedRoles: ["MyTime.examiner", "MyTime.admin"],
    rolesOnly: true,
    licence: "myTime",
  },
  {
    id: "myTimeCollapse",
    type: "collapse",
    label: "MyTime",
    icon: clockIcon,
    badge: <MyTimeMenuBadges urgent={true} />,
    roles: ["MyTime.examiner", "MyTime.admin"],
    rolesOnly: true,
    licence: "myTime",
    children: [
      {
        id: "myTimeEmployeeSchedule",
        type: "item",
        path: "/my-time/employee-schedule",
        label: "MyRequests",
        translation: "MY_WORK_TIME_REPORTS",
        roles: ["MyTime.user"],
        rolesOnly: true,
        icon: Arrow,
        narrowMenuIcon: clockIcon,
      },
      {
        id: "myTimeApproveRequests",
        type: "item",
        path: "/my-time/approve-requests",
        label: "Requests",
        translation: "REQUESTS",
        roles: ["MyTime.examiner"],
        rolesOnly: true,
        icon: Arrow,
        narrowMenuIcon: clockIcon,
        badge: <MyTimeMenuBadges urgent={true} />,
      },
      {
        id: "myTimeClosingPeriod",
        type: "item",
        path: "/my-time/closing-current-period",
        label: "Requests",
        translation: "CLOSING_PERIOD",
        roles: ["MyTime.admin"],
        rolesOnly: true,
        icon: Arrow,
        narrowMenuIcon: clockIcon,
      },
    ],
  },

  {
    id: "myCafeteria",
    type: "item",
    path: "/my-cafeteria",
    label: "MyCafeteria",
    icon: cafeteriaIcon,
    narrowMenuIcon: cafeteriaIcon,
    roles: ["MyCafeteria.user"],
    notAllowedRoles: [],
    licence: "myCafeteria",
  },
  {
    id: "settingCollapse",
    type: "collapse",
    label: "Settings",
    translation: "SETTINGS",
    icon: settingsIcon,
    roles: ["MyHR.user"],
    licence: "myHR",
    children: [
      {
        id: "myProfile",
        type: "item",
        path: "/my-hr/my-profile",
        label: "My profile",
        translation: "MY_PROFILE",
        narrowMenuIcon: settingsIcon,
        roles: ["MyHR.user"],
        icon: Arrow,
      },
      {
        id: "userGuides",
        type: "item",
        path: "/my-hr/user-guides",
        label: "User guides",
        translation: "USER_GUIDES",
        narrowMenuIcon: settingsIcon,
        roles: ["MyHR.user"],
        icon: Arrow,
      },
      {
        id: "settingsSubmenu",
        type: "submenu",
        label: "System",
        translation: "SYSTEM",
        roles: ["MyHR.admin", "MyCafeteria.admin", "MyTime.admin"],
        icon: Arrow,
      },
    ],
  },
];

export const settingsSubmenu = [
  {
    id: "backToMainMenu",
    type: "backMenu",
    targetMenu: "mainMenu",
    label: "Menü",
    translation: "MENU",
    roles: ["MyHR.admin", "MyCafeteria.admin", "MyTime.admin"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "systemGroup",
    type: "group",
    label: "System",
    translation: "SYSTEM",
    roles: ["MyHR.admin"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "users",
    type: "item",
    path: "/my-hr/users",
    label: "Users",
    translation: "GET_USERS",
    icon: accountOutlineIcon,
    narrowMenuIcon: accountOutlineIcon,
    roles: ["MyHR.admin"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "systemSettings",
    type: "item",
    path: "/my-hr/system-settings",
    label: "System settings",
    translation: "SYSTEM_SETTINGS",
    icon: settingsOutlineIcon,
    narrowMenuIcon: settingsOutlineIcon,
    roles: ["MyHR.admin"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "myLeaveGroup",
    type: "group",
    label: "Module",
    translation: "MODULE",
    roles: ["MyCafeteria.admin", "MyHR.admin", "MyTime.admin"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "myHrSettings",
    type: "item",
    path: "/my-hr/admin/settings",
    label: "MyHR",
    icon: accountOutIcon,
    narrowMenuIcon: accountOutIcon,
    roles: ["MyHR.admin"],
    notAllowedRoles: [],
    licence: "myHR",
  },
  {
    id: "myLeaveSettings",
    type: "item",
    path: "/my-leave/admin/settings",
    label: "MyLeave",
    icon: bagIconOutline,
    narrowMenuIcon: bagIconOutline,
    roles: ["MyHR.admin"],
    notAllowedRoles: [],
    licence: "myLeave",
  },
  {
    id: "myCafeteriaSettings",
    type: "item",
    path: "/my-cafeteria/admin/settings",
    label: "MyCafeteria",
    icon: cafeteriaSettingsIcon,
    narrowMenuIcon: cafeteriaSettingsIcon,
    roles: ["MyCafeteria.admin"],
    notAllowedRoles: [],
    licence: "myCafeteria",
  },
  {
    id: "myTimeSettings",
    type: "item",
    path: "/my-time/admin/settings",
    label: "MyTime",
    icon: clockIcon,
    narrowMenuIcon: clockIcon,
    roles: ["MyTime.admin"],
    notAllowedRoles: [],
    licence: "myTime",
  },
];
